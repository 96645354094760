import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'

// The following ensures that font-awesome loads css before
// svgs to prevent a jump in size.
import '@fortawesome/fontawesome-svg-core/styles.css'
import { config as faConfig } from '@fortawesome/fontawesome-svg-core'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTerminal, faBars, faTimes } from '@fortawesome/free-solid-svg-icons'

import '../css/header.scss'

faConfig.autoAddCss = false // prevents double load of fa styles
const { useState } = React

const Header = ({ siteTitle, navLinks }) => {
  const [showMenu, setShowMenu] = useState(false)
  const toggleMenu = () => {
    setShowMenu(!showMenu)
  }
  return (
    <header>
      <nav className="p-8 text-xl font-semibold flex-col md:flex-row md:justify-between md:items-center nav">
        <div className="flex justify-between">
          <h1>
            <Link to="/">{siteTitle.toLowerCase().replace(' ', '')}</Link>
          </h1>
          <a onClick={toggleMenu} tabIndex="0" className="md:hidden px-1 -mr-1 cursor-pointer hover:text-teal-500 active:text-teal-500">
            <FontAwesomeIcon className={`${showMenu ? 'hidden' : ''}`} icon={faBars} />
            <FontAwesomeIcon className={`${showMenu ? '' : 'hidden'}`}icon={faTimes} />
          </a>
        </div>
        <div className={`${showMenu ? '' : 'hidden'} flex md:flex flex-col pt-4 md:pt-0 md:flex-row`}>
          {navLinks.map(link => {
            return (
              <Link key={link.to} onClick={toggleMenu} partiallyActive={link.to !== '/'} activeClassName="active" className="nav-item" to={link.to}>
                <small className="nav-icon"><FontAwesomeIcon icon={faTerminal} /></small> {link.text}
              </Link>
            )
          })}
        </div>
      </nav>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
  navLinks: PropTypes.array
}

Header.defaultProps = {
  siteTitle: '',
  navLinks: [
    { to: '/', text: 'root' },
    { to: '/blog/', text: 'blog' },
    { to: '/photography/', text: 'photography' }
  ]
}

export default Header
